import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"

import Header from "../components/header"
import Footer from "../components/footer"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import { StaticImage } from "gatsby-plugin-image"

import * as productStyles from "./product.module.css"

export default function Product() {
  return (
    <>
      <SEO
        title="Nickelled Product Tour"
        description="Guided tours, hotspots and Help Assistant. How Nickelled helps train your users."
      />
      <Header />

      <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
        <Intercom color="#FF9C43" />
      </LiveChatLoaderProvider>
      <div className="w-full">
        <div className="max-w-7xl mx-auto p-4 flex flex-row justify-center items-center my-8 md:my-24">
          <h1 className="text-oOrange text-center font-bold text-3xl md:text-6xl">
            How <span className="text-oGrey">Nickelled</span> Helps
          </h1>
        </div>
      </div>

      <div
        className={
          "w-full bg-gradient-to-r from-oRed to-oOrange " + productStyles.header
        }
      >
        <div className="flex flex-row flex-wrap md:flex-nowrap items-center">
          <div className="px-4 w-full md:w-3/5">
            <StaticImage
              src="../images/product/headerStock.png"
              placeholder="blurred"
              className="w-full"
            />
          </div>
          <div className="px-4">
            <div className="max-w-7xl mx-auto p-4 flex flex-col">
              <div className="py-8 text-white">
                <h2 className="text-3xl font-bold">For L&D Professionals</h2>
                <ul
                  className={
                    "list-none my-2 py-2 pl-4 " + productStyles.divLine
                  }
                >
                  <li>Train users in top workflows</li>
                  <li>Improve the ROI of your enterprise software</li>
                  <li>Minimize errors</li>
                  <li>Reduce training costs with real-time support</li>
                </ul>
              </div>
              <div className="py-8 text-white">
                <h2 className="text-3xl font-bold">For Businesses</h2>
                <ul
                  className={
                    "list-none my-2 py-2 pl-4 " + productStyles.divLine
                  }
                >
                  <li>Onboard new users and increase user satisfaction</li>
                  <li>Reduce churn rates</li>
                  <li>Improve feature adoption</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full ">
        <div
          className={"max-w-7xl mx-auto p-4 py-16 " + productStyles.connector}
        >
          <h2 className="text-center text-4xl font-bold text-navy">
            What We Do
          </h2>

          <div className="w-full flex flex-row flex-wrap-reverse md:flex-nowrap py-16 items-center text-navy text-lg ">
            <div className="md:w-1/2 md:pr-20 bg-white">
              <h3 className="text-3xl font-bold">Guided Tours</h3>
              <p className="max-w-prose">
                Nickelled guided tours show your users what they need to see on
                the page, dynamically and in real-time. Use them to walk users
                through a process, highlight important parts of your user
                interface, or orientate first-time visitors.
              </p>
              <div className="flex flex-row">
                <Link
                  to="/request-demo/"
                  class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                >
                  Book a Free Demo
                </Link>
              </div>
            </div>
            <div className="px-4 py-8 md:py-0">
              <StaticImage
                src="../images/product/G-03.png"
                className="rounded-md"
              />
            </div>
          </div>

          <div className="w-full flex flex-row flex-wrap py-16 items-center text-navy text-lg">
            <div className="py-8 md:py-0">
              <StaticImage
                src="../images/product/G-02.png"
                className="rounded-md"
              />
            </div>
            <div className="md:w-1/2 pl-0 md:pl-20 bg-white">
              <h3 className="text-3xl font-bold">Hotspots</h3>
              <p className="max-w-prose">
                Nickelled Hotspots are eye-catching additions to your page,
                which act as an anchor for learners to get further information.
                Use them to showcase new features or provide an obvious place
                for visitors to get more help.
              </p>
              <div className="flex flex-row">
                <Link
                  to="/request-demo/"
                  class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                >
                  Book a Free Demo
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row flex-wrap-reverse md:flex-nowrap py-16 items-center text-navy text-lg">
            <div className="md:w-1/2 md:pr-20 bg-white">
              <h3 className="text-3xl font-bold">Help Assistant</h3>
              <p className="max-w-prose">
                The Nickelled Help Assistant is an on-page widget that provides
                comprehensive support or learning resources for your visitors.
                It's always present on the page and can be activated and
                expanded with a single click. Once opened, the Help Assistant
                can launch guided tours, offer further help documentation, and
                even redirect your learners to a new area inside your app.
              </p>
              <div className="flex flex-row">
                <Link
                  to="/request-demo/"
                  class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                >
                  Book a Free Demo
                </Link>
              </div>
            </div>
            <div className="py-8 md:py-0">
              <StaticImage
                src="../images/product/G-01.png"
                className="rounded-md"
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"w-full bg-silver py-20 " + productStyles.base}>
        <div className="w-max-7xl mx-auto p-4">
          <div className="max-w-5xl mx-auto">
            <h2 className="text-center text-4xl font-bold text-navy">
              You've got a lot on your plate - learning a new tool shouldn't be
              another thing.
            </h2>
            <p className="text-center text-navy text-2xl italic">
              Nickelled is easy to set up and maintain.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-8 gap-y-8 max-w-7xl mx-auto text-navy py-16">
            <div className="flex flex-col">
              <div className="flex flex-row flex-nowrap items-start">
                <div className="pr-4">
                  <StaticImage
                    src="../images/product/studio.png"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="font-bold text-2xl mt-2">Nickelled Studio</h3>
                  <p className="text-lg mt-2 leading-tight">
                    Say hello to the world's fastest documentation creator.{" "}
                  </p>
                </div>
              </div>
              <div className="pl-8">
                <div className="w-full bg-white px-4 py-2 rounded-md">
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Create your content in a few clicks, and then publish it
                      in many formats.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Nickelled Studio requires zero coding or technical skills.
                      Simply point, click, and type.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row flex-nowrap items-start">
                <div className="pr-4">
                  <StaticImage
                    src="../images/product/customize.png"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="font-bold text-2xl mt-2">Customization</h3>
                  <p className="text-lg mt-2 leading-tight">
                    Content that looks and feels right for your users. every
                    time.
                  </p>
                </div>
              </div>
              <div className="pl-8">
                <div className="w-full bg-white px-4 py-2 rounded-md">
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Theme Nickelled in any way you'd like, from changing
                      bubble shapes to tweaking color choices.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Offer your content in multiple languages, and trigger the
                      right language using segmentation.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row flex-nowrap items-start">
                <div className="pr-4">
                  <StaticImage
                    src="../images/product/analytics.png"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="font-bold text-2xl mt-2">Analytics</h3>
                  <p className="text-lg mt-2 leading-tight">
                    Improve your content, constantly. Nickelled provides
                    analytics and insights to make sure that your content is
                    always working for your learners.{" "}
                  </p>
                </div>
              </div>
              <div className="pl-8">
                <div className="w-full bg-white px-4 py-2 rounded-md">
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Track completion and satisfaction rates for all your
                      content.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Monitor drop-offs to understand where you might
                      communicate better.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Optionally, track the progression of individual learners.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row flex-nowrap items-start">
                <div className="pr-4">
                  <StaticImage
                    src="../images/product/integration.png"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="font-bold text-2xl mt-2">Integration</h3>
                  <p className="text-lg mt-2 leading-tight">
                    Choose the easiest rollout strategy for you – we'll be there
                    to support you along the way.
                  </p>
                </div>
              </div>
              <div className="pl-8">
                <div className="w-full bg-white px-4 py-2 rounded-md">
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Cloud based guides: Capture your guides, and share them
                      with a simple URL. The easiest way to get started with
                      Nickelled.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Javascript installation: Have developer access to the app
                      you're looking to use Nickelled with? We can provide a
                      simple javascript snippet to get you started
                    </p>
                  </div>

                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Workforce browser extension: Need to roll Nickelled out
                      across the workforce seamlessly? Use our browser
                      extensions.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row flex-nowrap items-start">
                <div className="pr-4">
                  <StaticImage
                    src="../images/product/customize.png"
                    className="w-10"
                  />
                </div>
                <div className="flex flex-col">
                  <h3 className="font-bold text-2xl mt-2">Segmentation</h3>
                  <p className="text-lg mt-2 leading-tight">
                    Deliver the right guidance, at the right time. Nickelled's
                    advanced segmentation tools let you make sure that every
                    learner is on the right track.
                  </p>
                </div>
              </div>
              <div className="pl-8">
                <div className="w-full bg-white px-4 py-2 rounded-md">
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Start content automatically when a user lands on a certain
                      page or screen of your app.
                    </p>
                  </div>
                  <div className="flex flex-row flex-nowrap items-start">
                    <div className="pr-2 text-oOrange pt-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="my-2">
                      Pass advanced matching data (such as team or job title) to
                      further narrow down your audience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  )
}
